:root {
  --section-padding: 4em 2rem 2em;
  --color-font: #6d599a;
  --color-font-button: #9686b9;
  --color-background: #fefefe;
  --color-background-section: #fafaff;
  --color-background-item: #fefefe;
  --color-background-toggle: #c8bdd8;
  --color-background-button: #f1eef5;
  --color-background-button-active: #daf9ff;
}

@font-face {
  font-family: "NotoSansKR-Regular";
  src: url("./assets/fonts/NotoSansKR-Regular.otf");
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "NotoSansKR-Regular";
  font-size: 1em;
  min-width: 320px;
}

body.dark {
  --color-font: #e3e3e3;
  --color-font-button: #8b8b8b;
  --color-background: #282b2e;
  --color-background-section: #414348;
  --color-background-item: #282b2e;
  --color-background-toggle: #e3e3e3;
  --color-background-button: #f6f6f6;
  --color-background-button-active: #e3e3e3;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0.05em 0;
}

@media (min-width: 481px) {
  body {
    font-size: 1.1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0.15em 0;
  }
}

@media (min-width: 769px) {
  body {
    font-size: 1.2em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0.25em 0;
  }
}

@media (min-width: 1025px) {
  body {
    font-size: 1.3em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0.35em 0;
  }
}

@media (min-width: 1201px) {
  body {
    font-size: 1.4em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0.45em 0;
  }
}
